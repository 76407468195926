import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Link from "next/link";
import { CandidateFYILogo } from "../../assets";

const PageNotFound = () => {
  return (
    <Flex
      bg="green.50"
      justifyContent={"center"}
      direction={"column"}
      alignItems="center"
      h={"100vh"}
      p="24px"
    >
      <Box>
        <CandidateFYILogo height="200px" width="200px" />
      </Box>
      <Text
        color={"blue.900"}
        fontWeight={700}
        fontSize={{ base: "40px", md: "80px" }}
        mt={"40px"}
      >
        Nothing to see here!
      </Text>
      <Text
        mt={"24px"}
        color={"gray.600"}
        fontWeight={500}
        fontSize={{ base: "24px", md: "40px" }}
      >
        Sorry, it doesn’t look like there’s a page found here.
      </Text>
      <Link href="/">
        <Button
          colorScheme="blue"
          bg={"blue.900"}
          mt={"40px"}
          borderRadius="full"
          fontWeight={500}
          fontSize="18px"
        >
          Back to homepage
        </Button>
      </Link>
    </Flex>
  );
};

export default PageNotFound;
